<template>
  <div class="goods-order">
    <gl-title :title="$route.meta.title"></gl-title>
    <!-- <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="orderId" label="订单ID">
          <gl-list-search v-model="mFormData.orderId" placeholder="订单ID"></gl-list-search>
        </el-form-item>
        <el-form-item prop="title" label="商品标题">
          <gl-list-search v-model="mFormData.title" placeholder="商品标题"></gl-list-search>
        </el-form-item>
        <el-form-item prop="status" label="订单状态">
          <gl-option v-model="mFormData.status" :list="statusList"></gl-option>
        </el-form-item>
        <el-form-item prop="title" label="订单时间">
          <gl-date-picker v-model="mFormData.dateTime" :startTime.sync="mFormData.startTime" :endTime.sync="mFormData.endTime"></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header> -->
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="orderId" label="订单ID" width="100"></el-table-column>
        <el-table-column prop="itemName" label="商品标题"></el-table-column>
        <el-table-column prop="skuName" label="商品规格"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态">
          <template slot-scope="{row}">
            <span v-if="row.orderStatus == 1">待付款</span>
            <span v-if="row.orderStatus == 2">待发货</span>
            <span v-if="row.orderStatus == 3">待收货</span>
            <span v-if="row.orderStatus == 4">待评价</span>
            <span v-if="row.orderStatus == 5">已完成</span>
            <span v-if="row.orderStatus == 6">已取消</span>
            <span v-if="row.orderStatus == 7">已关闭</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="订单时间"></el-table-column>
        <el-table-column prop="payType" label="付款方式">
           <template slot-scope="scope">
            <span>{{payTypeGather[scope.row.payType]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payPrice" label="实付金额"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="$router.push({path: 'detail', query: {orderId: row.orderId}})"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {orderGoodsList} from '@/api/coox/orderGoods'
export default {
  name: 'orderList',
  mixins: [ListMixin],
  data(){
    return{
      statusList: [
        {name: '全部', value: ''},
        {name: '待付款', value: '1'},
        {name: '待发货', value: '2'},
        {name: '待收货', value: '3'},
        {name: '待评价', value: '4'},
        {name: '已完成', value: '5'},
        {name: '已取消', value: '6'},
        {name: '已关闭', value: '7'},
      ],
      payTypeGather:{
        '1': '微信',
        '2': '支付宝',
        '3': '余额',
        '4': '线下支付',
      },
      mTableData: [
        {
          orderId: 'orderId-1',
        },
      ],
    }
  },
  mounted(){
    this.mGetListFun = orderGoodsList
    this.mGetList()
  },
}
</script>

<style>

</style>